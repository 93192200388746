<template>
  <div>
    <b-card>

      <!-- form -->
      <b-form class="mt-1">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-3">
              <h3 class="mb-0 font-weight-normal">
                Payment Settings
              </h3>
            </div>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Tranzila API"
              label-for="tranzila-api"
              class="mb-2"
            >
              <b-form-input
                id="tranzila-api"
                v-model="tranzilaApi"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-3"
          >
            <b-button
              variant="primary"
              class="mr-1"
            >
              Save Settings
            </b-button>
            <b-button
              variant="outline-secondary"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      tranzilaApi: 'API_k328jnd3$#43fkjniunoiumsoim',
    }
  },
}
</script>

<style lang="scss">
</style>
